<template>
  <nav class="HeaderNavigation">
    <router-link class="link"
                 :to="getRoute('generalSettings')" @click.native="$emit('refreshStatusPage')">General Settings</router-link>
    <router-link class="link"
                 :to="getRoute('monitors')" @click.native="$emit('refreshStatusPage')">Monitors</router-link>
    <router-link class="link"
                 :to="getRoute('incidents')" @click.native="$emit('refreshStatusPage')">Incidents</router-link>
    <router-link class="link"
                 :to="getRoute('maintenance')" @click.native="$emit('refreshStatusPage')">Maintenance</router-link>
    <router-link class="link"
                 :to="getRoute('customization')" @click.native="$emit('refreshStatusPage')">Customization</router-link>
    <router-link class="link"
                 :to="getRoute('language')" @click.native="$emit('refreshStatusPage')">Translate</router-link>
    <!--<router-link class="link"
                 :to="getRoute('subscribers')">Subscribers</router-link>-->
  </nav>
</template>

<script>
import auth from '@/services/auth.js'

export default {
  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      plan_incident_updates: null
    }
  },

  created () {
    this.plan_incident_updates = auth.getUser().subscription_plan.status_page_incident_updates
  },

  methods: {
    getRoute (name) {
      return {
        name: `statusPages.single.${name}`,
        params: {
          id: this.statusPage.id
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .HeaderNavigation {
    display: flex;
    padding-top: 40px;
    //margin-bottom: 10px;

    .link {
      display: block;
      padding: 8px 12px 12px 12px;
      font-size: 15px;
      font-weight: 500;
      transition: color 0.2s;
      margin-bottom: -1px;

      &:first-child {
        padding-left: 0;
      }

      &:not(.router-link-active):not(.router-link-exact-active) {
        color: lighten(map-get($colors, gray-1), 20%);

        &:hover {
          color: map-get($colors, gray-1);
          border-bottom: 1px solid lighten(map-get($colors, gray-1), 20%);
        }
      }

      &.router-link-active {
        border-bottom: 2px solid map-get($colors, purple-1);
      }
    }
  }
</style>
