<template>
  <div v-if="statusPage">
    <status-page-header v-if="!isMaintenanceEditRoute" :status-page="statusPage" @refreshStatusPage="loadStatusPage"/>

    <router-view :status-page="statusPage" @refreshStatusPage="loadStatusPage"/>
  </div>
</template>

<script>
import StatusPageHeader from '@/components/StatusPage/Settings/Header/Header.vue'
import statusPagesApi from '@/api/statusPagesApi.js'

export default {
  components: {
    StatusPageHeader
  },

  props: {
    id: {
      required: true
    }
  },

  data () {
    return {
      statusPage: null
    }
  },

  created () {
    this.loadStatusPage()
  },

  methods: {
    async loadStatusPage () {
      this.statusPage = await statusPagesApi.find(this.id)
    }
  },
  computed: {
    isMaintenanceEditRoute () {
      return !!this.$route.params.maintenanceId
    }
  }
}
</script>
